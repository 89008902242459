


import React from 'react';

const Loading = () => {
  return (
    <div className="loading">
    </div>
  );
}

export default Loading;